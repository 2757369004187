import { goto } from "$app/navigation";
import type { DateField } from "@prismicio/client";
import type { AfterNavigate } from "@sveltejs/kit";

let scrollObject = {
    x: 0,
    y: 0
};

export const getScrollDistance = () => {
    if (scrollObject.y > window.scrollY) {
        document.body.classList.add('scrolled-up');
    } else {
        document.body.classList.remove('scrolled-up');
    }

    scrollObject = {
        x: (window.scrollX - 2),
        y: (window.scrollY - 2)
    }

    if (scrollObject.y > 0) {
        document.body.classList.add('scrolled');
    } else {
        document.body.classList.remove('scrolled');
        document.body.classList.remove('menu-open');
        document.body.classList.remove('show-section-title');
    }
}

export const toggleMenu = (event: MouseEvent | null) => {
    if (event) event.preventDefault();
    document.body.classList.toggle('menu-open');
}

export const getParents = function (elem: HTMLElement | ParentNode | null, targetStyle: string | null = null) {
    const els = [];
    while (elem) {
        els.unshift(elem);
        const parentElement = elem.parentNode as HTMLElement;
        if (parentElement) {
            if (targetStyle && parentElement.style != undefined) {
                elem = parentElement;
                console.log(`${targetStyle}: ${parentElement.style.getPropertyValue(targetStyle)}`);
            } else {
                elem = parentElement;
            }
        } else {
            elem = elem.parentNode;
        }
    }

    return els;
};

let lastIndicatorPosition: number = -10;
export const updateNavigationActiveState = (navigation: AfterNavigate) => {
    const pathname = navigation.to?.url.pathname;
    const navContainer = document.querySelector('nav.main-nav');
    const navLinks = navContainer?.querySelectorAll('ul.nav li a');
    let newActive: HTMLElement | undefined;

    navLinks?.forEach((el) => {
        const link = el as HTMLLinkElement;
        if (link.href) {
            const href = new URL(link.href);
            if (pathname === href.pathname) {
                el.classList.add('active');
                newActive = el as HTMLElement;
            } else if (href.pathname !== '/' && pathname?.startsWith(href.pathname)) {
                el.classList.add('active');
                newActive = el as HTMLElement;
            } else {
                el.classList.remove('active');
            }
        }
    });

    const navIndicator = navContainer?.querySelector('.main-nav__indicator') as HTMLElement;
    if (newActive !== undefined) {
        if (newActive.offsetParent !== null) {
            const newActivePosition = newActive.offsetLeft;
            const newActiveWidth = newActive.offsetWidth;
            const newXPos = (newActivePosition + (newActiveWidth / 2));
            navIndicator.style.left = `${newXPos}px`;
            lastIndicatorPosition = newXPos;
        } else {
            navIndicator.style.left = '-10px';
        }
    } else {
        navIndicator.style.left = '-10px';
    }
};

export const navIndicatorHover = (event: MouseEvent | FocusEvent) => {
    const target = event.target as HTMLElement;
    const targetPosition = target.offsetLeft;
    const targetWidth = target.offsetWidth;
    const navIndicator = document.querySelector('.main-nav__indicator') as HTMLElement;
    const active = document.querySelector('ul.nav li a.active') as HTMLElement;
    if (active) {
        lastIndicatorPosition = (active.offsetLeft + (active.offsetWidth / 2));
    }

    const newXPos = (targetPosition + (targetWidth / 2));
    navIndicator.style.left = `${newXPos}px`;
};

export const navIndicatorBlur = () => {
    const navIndicator = document.querySelector('.main-nav__indicator') as HTMLElement;
    navIndicator.style.left = `${lastIndicatorPosition}px`;
    lastIndicatorPosition = -10;
};

export const scrollToTop = (event: MouseEvent) => {
    event.preventDefault();
    window.scrollTo(0,0);
};

export const showMenu = () => {
    document.body.classList.remove('hide-menu');
};

export const hideMenu = () => {
    document.body.classList.add('hide-menu');
};

export function publicationDateToISO(date: DateField | Date | string) {
    const dateString = date as string;
	const dateObject = new Date(dateString);
	return dateObject.toISOString();
}

export function scrollToElement(document: Document, event: MouseEvent, url: string | URL | boolean) {
    const target = event.target as HTMLElement;

    if (target) {
        event.preventDefault();
        target.scrollIntoView({ behavior: 'smooth' });

        if (url) {
            const newUrl  = url as string;
            const onScroll = () => {
                const rect = target.getBoundingClientRect();

                if (rect.top === 0) {
                    if (document.startViewTransition) {
                        document.startViewTransition(() => {
                            goto(newUrl);
                        });
                    } else {
                        goto(newUrl);
                    }
                    window.removeEventListener('scroll', onScroll);
                }
            };

            window.addEventListener('scroll', onScroll);
        }
    }
}
