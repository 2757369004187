/**
 * To debug add .debug-color-triggers to body element
 */

/**
 * ColorTrigger
 * @param element
 * @param scrollDirection
 */
export const ColorTrigger = (element: HTMLElement, scrollDirection: DOMStringMap) => {
    const data = element.dataset;

    if (Object.keys(scrollDirection).length < 1) {
        const backgroundColor = data.background ?? '--bs-body-bg';
        const foregroundColor = data.foreground ?? '--bs-body-color';
        const styleString = `--colorTriggerBackground:${backgroundColor};--colorTriggerForeground:${foregroundColor};`;
        document.body.setAttribute('style', styleString);
        document.body.classList.add('color-trigger');
    } else {
        const backgroundColor = data.background ?? '--bs-body-bg';
        const foregroundColor = data.foreground ?? '--bs-body-color';
        const styleString = `--colorTriggerBackground:${backgroundColor};--colorTriggerForeground:${foregroundColor};`;
        document.body.setAttribute('style', styleString);
        document.body.classList.add('color-trigger');
    }
}

/**
 * TriggerLastColor
 * @param scrollDirection
 */
export const TriggerLastColor = (scrollDirection: DOMStringMap) => {
    if (scrollDirection.vertical == 'down') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const triggers: any[] = [];
        const triggerElements = document.querySelectorAll('.color-trigger__point');

        [...triggerElements].forEach((element) => {
            if (element.getBoundingClientRect().top < 1) {
                triggers.push(element);
            }
        });

        const lastTrigger = triggers[triggers.length - 1];
        if (lastTrigger !== undefined) {
            ColorTrigger(lastTrigger, {});
        } else {
            ClearColorTrigger();
        }
    }
}

export const ClearColorTrigger = () => {
    document.body.classList.remove('color-trigger');
    document.body.style.removeProperty('--colorTriggerBackground');
    document.body.style.removeProperty('--colorTriggerForeground');
};

export const TriggerCurrentColor = (element: HTMLElement) => {
    ColorTrigger(element, {
        vertical: 'up',
        horizontal: 'left'
    });
};

export const TriggerHeaderColor = (color: string | null = null) => {
    const mainHeader = document.querySelector('header.main-header') as HTMLElement;
    if (mainHeader && color !== null) {
        mainHeader.style.setProperty('--headerContentColor', String(color));
    } else {
        mainHeader.style.removeProperty('--headerContentColor');
    }
}
